// Next.js
import Link from '../Link'
import Image from 'next/image'

// React components
import Button from '../Button'

// Styles
import {
  PromoBannerWrapper,
  ImageWrapper,
  Container,
  Text,
  ButtonWrapper,
} from './PromoBanner.styles'
import { Title } from '../../styled-components/shared/common'

// Types
import { PromoBannerProps } from './PromoBanner.types'

const PromoBanner = ({
  title,
  description,
  buttonText,
  href,
  imageUrl,
  imageAlt,
  openInNewWindow,
}: PromoBannerProps) => {
  return (
    <PromoBannerWrapper>
      <Link href={href} passHref legacyBehavior>
        <ImageWrapper>
          <Image
            src={imageUrl}
            layout='fill'
            alt={imageAlt}
            objectFit='cover'
            priority={true}
          />
        </ImageWrapper>
      </Link>
      <Container>
        <Title>{title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: description || '' }} />
        <ButtonWrapper>
          <Link href={href} passHref legacyBehavior>
            {openInNewWindow ? (
              <Button variant='outlined' arrow={true} link target='_blank'>
                {buttonText}
              </Button>
            ) : (
              <Button variant='outlined' arrow={true} link>
                {buttonText}
              </Button>
            )}
          </Link>
        </ButtonWrapper>
      </Container>
    </PromoBannerWrapper>
  )
}
export default PromoBanner
